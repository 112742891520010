
import { useEffect, useState } from 'react';
import axios from 'axios';
import './Userglucose.css';
import { LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import moment from 'moment';
import Loader from '../Loader/Loader';
import { Select } from '@mantine/core';
import React from "react";
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import 'moment-timezone';
import { Password, Snowflake } from 'tabler-icons-react';
//let now = dayjs();


var data = [{ value: '1', label: 'Monday' },{ value: '2', label: 'Tuesday' },{ value: '3', label: 'Wednesday' },{ value: '4', label: 'Thursday' },{ value: '5', label: 'Friday' },{ value: '6', label: 'Saturday' },{ value: '7', label: 'Sunday' }];

const base_url = process.env.REACT_APP_BASE_URL;
function Userglucose() {

  const DATE = localStorage.getItem("DATE");
 let tm = moment().format('HH:mm:s');
  let ct = moment(DATE + tm, 'YYYY-MM-DD HH:mm:s').format();
  let past_ct = moment(ct).subtract(24,"hours").format();
  let timeArray = [];




const [fasting, setFasting] = useState([]);
const [gmi, setGmi] = useState([]);
const [range, setRange] = useState([]);
const [gchart, setGChart] = useState([]);
const [wchart, setwChart] = useState([]);
const [rangeAve, setrangeAve] = useState([]);
const [Extrarange, setExrtarange] = useState([]);
const [rangeAveDay, setrangeAveDay] = useState([]);
const [loaded, dataLoading] = useState(false);
const [loaded1, dataLoading1] = useState(false);
const [loaded2, dataLoading2] = useState(false);
const [loaded3, dataLoading3] = useState(false);
const [loaded4, dataLoading4] = useState(false);
const [loaded5, dataLoading5] = useState(false);
const [loaded6, dataLoading6] = useState(false);
const [loaded7, dataLoading7] = useState(false);

const queryParameters = new URLSearchParams(window.location.search);
const UID = queryParameters.get("user");
const user = localStorage.getItem("user").replace(/['"]+/g, '');

const startDay =(moment(DATE).startOf('isoWeek').format('YYYY-MM-DD'));
const endDay = (moment(DATE).endOf('isoWeek').format('YYYY-MM-DD'));

const startDay3 = moment(DATE).subtract(2, 'days').format('YYYY-MM-DD');
const endDay3 = moment(DATE).format('YYYY-MM-DD');
const startDay1 =moment(DATE).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
const endDay1 = moment(DATE).endOf('isoWeek').format('YYYY-MM-DD');
const startDay4 = moment(DATE).subtract(6, 'days').format('YYYY-MM-DD');
const startDay6 = moment(DATE).format('YYYY-MM-DD');
//const [startDay7 , setstartDay7] = useState(endDay3);

const mealch = { "uuid": UID, "start_date":startDay, "end_date":endDay, "is_admin": 'True'}
const mealch1 = { "uuid": UID, "start_date":startDay1, "end_date":endDay1, "is_admin": 'True'}
const mealch2 = { "uuid": UID, "start_date":startDay, "end_date":endDay, "is_admin": 'True'}
const mealch3 = { "uuid": UID, "start_date":startDay3, "end_date":endDay3, "is_admin": 'True'}
const mealch4 = { "uuid": UID, "start_date":startDay4, "end_date":endDay1}
const mealch5 = { "uuid": UID, "start_date":startDay1, "end_date":endDay1, 'is_one_day': false}
const mealch6 = { "uuid": UID, "start_date":startDay, "end_date":endDay1, 'is_one_day': true}


const [initialapiload, setinitapiload] = useState(true)
const [userGrange, setGrange] = useState([])

async function getData(){      
       try{
        const response = await axios({ method: "post", url:base_url+"/graph/glucose-fasting-data", data:mealch, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`}})
        if(response.data.response!=="Error while fetching data"){ setFasting([response.data.response])}; 
        } finally { dataLoading(true);} 
      }
async function getData1(){
    try{
      const response = await axios({ method: "post", url:base_url+"/graph/glucose-gmi-data", data: mealch1, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
      if(response.data.response!=="Error while fetching data"){ setGmi([response.data.response.sort(function (left, right) {
        return moment.utc(left.date).diff(moment.utc(right.date))
    })])}; 
    } finally { dataLoading1(true);}; 
}
async function getData2(){
      try{
        const response = await axios({ method: "post", url:base_url+"/graph/glucose-rangescore-data", data: mealch2, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
     if(response.data.response!=="Error while fetching data"){setRange([response.data.response])};
    } 
     finally {dataLoading2(true); };
} 
async function getData3(){
  try{
   const response = await axios({ method: "post", url:base_url+"/graph/glucose-oneday-graph-data", data: mealch3, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
    if(response.data.response!=="Error while fetching data"){ setGChart([response.data.response ])};
    if(response){dataLoading3(true);
      setGrange(response?.data?.glucose_data_of_user?.[0])
    }
   } 
    finally {dataLoading3(true); }; 
 }
async function getData4(){    
   try{
     const response = await axios({method: "post", url:base_url+"/graph/glucose-graph-data", data: mealch4, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
     if(response.data.response!=="Error while fetching data"){setwChart([response.data.response])};
   } 
     finally {dataLoading4(true); };
} 
async function getData5(){
  try{
    const response = await axios({method: "post", url:base_url+"/graph/daily-glucose-average", data: mealch5, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
    if(response.data.response!=="Error while fetching data"){

      //console.log(response.data.response);
      setrangeAve([response.data.response.sort(function (left, right) {
        return moment.utc(left.date).diff(moment.utc(right.date))
    })])
   };} finally {dataLoading5(true); };}
async function getData6(){       
   if(initialapiload===true){
   try{
    const response = await axios({method: "post", url:base_url+"/graph/daily-glucose-average", data: mealch6, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
    if(response.data.response!=="Error while fetching data"){
      setrangeAveDay([response.data.response.sort(function (left, right) {
        return moment.utc(left.date).diff(moment.utc(right.date))
    })])
      setinitapiload(false)
   };} finally {dataLoading6(true);};}}
async function getData7(){
  try{
    const response = await axios({ method: "post", url:base_url+"/graph/glucose-rangescore-data-extra", data: mealch, 'Content-Type': 'application/json',crossDomain: true, headers: {"Authorization" : `Bearer ${user}`} })
    if(response.data.response!=="Error while fetching data"){ setExrtarange([response.data.response])};
  } 
    finally {dataLoading7(true); }; 
}

  const [fastArr, setfastArr] = useState([]);
  const [rangeArr, setrangArr] = useState([]);
  const [gchartArr, setGchartArr] = useState([]);
  const [wchartArr, setwchartArr] = useState([]);
  const [wchartArr1, setwchartArr1] = useState([]);
const [intialLoad3, setinit3] = useState(false);

  /*  const updateState0 = () => {
    if(Array.isArray(gchart?.[0]?.latest_24hrs_data)){
      const newState = (gchart[0].latest_24hrs_data).map((obj, i) => {
       setinit3(true)
      if(moment(obj.timestamp).isBefore(ct) && moment(obj.timestamp).isAfter(past_ct) ){
       return {...obj, t: moment(obj.timestamp).format('H')}
       }
        return null;
    }).filter((item)=> item !== null)
    setGchartArr(newState.map((n,i)=>{ return {...n, index: i}})
    
    ); setinit3(true)}
} */

const updateState0 = () => {
  if(Array.isArray(gchart?.[0]?.latest_24hrs_data)){
    const newState = (gchart[0].latest_24hrs_data).map((obj, i) => {
     setinit3(true)
    var convertedTime = new Date(moment.utc(obj.timestamp).toISOString());
     if(moment(convertedTime).isBefore(ct) && moment(convertedTime).isAfter(past_ct) ){
      return {...obj,t2 : moment(convertedTime).format('X'),converted:convertedTime}
     } 
      return null;
  }).filter((item)=> item !== null)
  setGchartArr(newState.sort((a,b)=> a.t2 - b.t2)/* .map((n,i)=>{ return {...n, index:i}} )*/ );  setinit3(true) 
}

}


const [initialfil, setinfilter] = useState('6hours');
const [intialLoad4, setinit4] = useState(false);
        const updateState = () => {
          if(Array.isArray(range[0])){
          const newState = range[0].slice(0).reverse().map(obj => {
            setinit4(true)
              if (obj.date) {
                return {...obj, day: moment(obj.date).format("ddd")};
              }
            return obj;
          }).sort(function (left, right) {
            return moment(left.date).diff(moment(right.date))
        });
          setrangArr(newState);
        };
      }  

  const [intialLoad5, setinit5] = useState(false);
      const updateState1 = () => {
        if(Array.isArray(fasting[0])){
        const newState = fasting[0].slice(0).reverse().map(obj => {
            if (obj.date) {
              return {...obj, day: moment(obj.date).format("ddd")};
            }
          return obj;
        }).sort(function (left, right) {
          return moment(left.date).diff(moment(right.date))
      });
        setfastArr(newState);
        setinit5(true)
      };
    } 

const sdg1 = moment(DATE).startOf('isoWeek').format('YYYY-MM-DD');
const edg1 = DATE;
const [intialLoad6, setinit6] = useState(false);
  const updateState3 = (s, e) => {
    if(Array.isArray(wchart[0])){
    const newState = wchart[0].slice(0).reverse().map(obj => {
      setinit6(true)
        if (obj.timestamp) {
          return {...obj, day: moment(obj.date).format("ddd")};
        }
      return obj;
    }).sort(function (left, right) {
      return moment(left.date).diff(moment(right.date))
  });
    
    setwchartArr1(newState);
    var a = wchartArr1.findIndex((obj)=> ((moment(obj.date).format('YYYY-MM-DD')) === s));
    var b = wchartArr1.findIndex((obj)=> ((moment(obj.date).format('YYYY-MM-DD')) === e));
    setwchartArr(wchartArr1.slice(a, b+1));
  };
} 

const [extrarangeF, setextrarangeF] =useState([])
const [intialLoad7, setinit7] = useState(false);
const updateState7 = () => {
  if(Array.isArray(Extrarange[0])){
    
  const newState = Extrarange[0].slice(0).reverse().map(obj => {
    setinit7(true)
      if (obj.total_above_180 > 0) {
        return {...obj, cat_180: obj.total_above_180, day: moment(obj.date).format("ddd")};
      }else if(obj.total_below_70 > 0){
        return {...obj, cat_70: obj.total_below_70, day: moment(obj.date).format("ddd")};
      }else{
        return {...obj, day: moment(obj.date).format("ddd")};
      }
  });
  setextrarangeF(newState.sort(function (left, right) {
    return moment.utc(left.date).diff(moment.utc(right.date))
}));
  
};
} 

const [fortyday, setforty] = useState([]);
const [intialLoad, setinit] = useState(false);
const [selectedItem, setSelected] = useState('first');
const nextPrev = (action) => {
  if(Array.isArray(rangeAve[0])){ 
if(action==='last'){
    const s = moment(DATE).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    const e = moment(DATE).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
    var a =  parseInt(rangeAve[0].findIndex((obj)=> (obj.date === s)));
    var b =  parseInt(rangeAve[0].findIndex((obj)=> (obj.date === e)));
    setforty(rangeAve[0].slice(a, b+1));
    setinit(true)
} else if(action==='first'){
    const s = moment(DATE).startOf('isoWeek').format('YYYY-MM-DD');
    const e = moment(DATE).endOf('isoWeek').format('YYYY-MM-DD');
    var a2 = parseInt(rangeAve[0].findIndex((obj)=> (obj.date === s)));
    var b2 =  parseInt(rangeAve[0].findIndex((obj)=> (obj.date === e)));
    setforty(rangeAve[0].slice(a2, b2+1));
    setinit(true)
}}
} 
const [fortyday1, setforty1] = useState([]);
const [intialLoad1, setinit1] = useState(false);
const [selectedItem1, setSelected1] = useState('first');
const nextPrevGMI = (action) => {
  if(Array.isArray(gmi[0])){
    if(action==='last'){
      const s = moment(DATE).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
      const e = moment(DATE).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
      var a1 = parseInt(gmi[0].findIndex((obj)=> (obj.date === s)));
      var b1 = parseInt(gmi[0].findIndex((obj)=> (obj.date === e)));
      setforty1(gmi[0].slice(a1, b1+1));
      setinit1(true)
  } else if(action==='first'){
      const s = moment(DATE).startOf('isoWeek').format('YYYY-MM-DD');
      const e = moment(DATE).endOf('isoWeek').format('YYYY-MM-DD');
      var aa = parseInt(gmi[0].findIndex((obj)=> (obj.date === s)));
      var bb = parseInt(gmi[0].findIndex((obj)=> (obj.date === e)));
      setforty1(gmi[0].slice(aa, bb+1));
      setinit1(true)
  }}
}

const CustomizedDot = () => {};
const [daysNav, setdayNav] = useState('0');
const dayscroll = (val)=>{if(val==='yes'){setdayNav('1')} else{setdayNav('0')};}
const [daysNav1, setdayNav1] = useState('0');
const dayscroll1 = (val)=>{if(val==='yes'){setdayNav1('1')} else{setdayNav1('0')};}
const defaultDate =  moment(startDay6, "YYYY-MM-DD").weekday();
const [day, setDay]  =  useState((moment(DATE).weekday()));


  useEffect(() => {

    if(intialLoad===false){nextPrev('first'); }
    if(intialLoad1===false){ nextPrevGMI('first')}
     if(loaded3 === true){ updateState0(); 
    } 

  
    if(loaded3 === true && intialLoad3===false){updateState0();}
    if(intialLoad4===false && loaded2 === true){updateState();}
    if(intialLoad5===false && loaded === true){updateState1(); }
    if(intialLoad6===false && loaded4 === true){ updateState3(); }
    if(intialLoad7===false && loaded7 === true){ updateState7();}

    if(loaded===false){ getData();}
    if(loaded1===false){getData1(); }
    if(loaded2===false){getData2(); }
    if(loaded3===false ){getData3();}
    if(loaded4===false ){getData4(); }
    if(loaded5===false ){getData5();  }
    if(loaded6===false ){getData6();}
    if(loaded7===false ){getData7();}
    
  }, [fasting, gmi, sdg1, edg1, gchart, wchart ]);



  const formatXAxis = (tickFormat) => {
  return moment( tickFormat, 'X').format('ha');
  };

  const CustomTooltip = ({ active, payload, label }) => {
     return (
      <p className="label">{moment(`${payload?.[0]?.payload?.converted}`).format('h:mm a')} <br />
         { `${payload?.[0]?.value}` }
      </p>
    )
  }

  let hr = moment(past_ct).format('YYYY-MM-DD HH');

  for (let i = 0; 25 > i; i=i+3) {
    timeArray.push( moment(hr, 'YYYY-MM-DD HH').add(i, 'hours').format('X'))
  }


  return (
    
    <div className='col col-100'>
                  <h2>Glucose Monitoring</h2>
                  <div className='col-group two-col'>


                  <div className='col-wrapper exercise full-width-blk' id='gcs'>
                  <div className='filter-wrapper'>

                    <div className='left-div'><div className='total-cal dark-text'>Glucose Chart  <span>mmol/l</span></div>
                      
                    </div>
                    <div className='right-div'>
                      <Select dropdownPosition="bottom" placeholder='Last 24 hours' defaultValue={'6hours'}  data={[{value: '6hours', label: 'Last 24 hours'}, {value: 'week', label: 'Week'}, {value: 'last7days', label: 'Last 7 Days'}]} onChange={(val) => {/* onChangeval(val) */
                    setinfilter(val);
                    if(val==='last7days'){
                     updateState3(moment(DATE).subtract(6, 'days').format('YYYY-MM-DD'), DATE)
                    } else if (val==='week'){
                       updateState3(moment(DATE).startOf('isoWeek').format('YYYY-MM-DD'), moment(DATE).endOf('isoWeek').format('YYYY-MM-DD'))
                    }else{
                    //updateState0()
                    }
                    
                    } } ></Select>
                    </div>
                  </div>

                      
           
           
                { initialfil === '6hours' && Array.isArray(gchart) ?
                 <>
  { loaded3 === false ?  <Loader /> : (

              
 
                <>
                   

<ResponsiveContainer  width={'100%'} height={300} >
 
  <LineChart  data={gchartArr}  margin={{ top: 5, right: 0,  left: 0, bottom: 5, }} >
                        
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                    
                        <XAxis   axisLine={true} type="number" domain={[parseInt(timeArray?.[0]), parseInt(timeArray?.[timeArray?.length - 1])]} tickLine={true} padding={{ left: 20, right: 20}} ticks={timeArray} dataKey='t2' interval={0} tickFormatter={(tick) => formatXAxis(tick)} />
                      
                       <YAxis type="number" padding={{top:1}}  axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[0, 15]} minTickGap={50} tickCount='6' interval={0} ticks={['0','3', '6', '9', '12', '15']} />
                       
                       <Tooltip label='Time'  content={<CustomTooltip />} />
                       <ReferenceLine y={userGrange?.upper_range}  stroke="#EC0909" label={userGrange?.upper_range+' mmol/l'}/>
                        <ReferenceLine y={userGrange?.lower_range}  stroke="#6CF600" label={userGrange?.lower_range+' mmol/l'} />
                       <Line connectNulls allowDecimals={false} dot={true} isAnimationActive={false} activeDot={true} type="monotone" dataKey="blood_glucose_mg_per_dL" stroke="#0051A6" name="Glucose"  />
                     
                     </LineChart></ResponsiveContainer>
                     
                     
                   </>
                   )} 
                   </>
                  :''}

                { (initialfil === 'week' || initialfil === 'last7days') && Array.isArray(wchartArr) ?
                <>
                { loaded4 === false ?  <Loader /> : (
                
                <ResponsiveContainer  width={'100%'} height={300}><LineChart  data={wchartArr} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                        <ReferenceLine y={userGrange?.upper_range}  stroke="#EC0909" label={userGrange?.upper_range+' mmol/l'}/>
                        <ReferenceLine y={userGrange?.lower_range}  stroke="#6CF600" label={userGrange?.lower_range+' mmol/l'} />
                       <XAxis axisLine={false} tick={{ fill: '#111' }} tickLine={false} padding={{ left: 20, right: 20 }}  minTickGap={10}  dataKey="date"  tickFormatter={(date) => moment(date).format("ddd")}/>
                       <YAxis padding={{top:1}} axisLine={false} tick={{ fill: '#111' }} tickLine={false} domain={[0, 15]} minTickGap={50}  tickCount='6' interval={0}  ticks={['0','3', '6', '9', '12', '15']}  />
                       <Tooltip  name='name' label={'label'} />
                       <Line dot={true} connectNulls  isAnimationActive={false} activeDot={true}  allowDecimals={false} type="monotone" dataKey="blood_glucose_mg_per_dL" stroke="#0051A6" name='Glucose'  />
                     </LineChart></ResponsiveContainer>
                )}
                <div className='chartlabel'>Week</div>
                </>
                  :''}
                </div>

                <div className='col-wrapper exercise' id="gcs1">
                  <div className='total-cal dark-text'>Range Score</div>   
                  { loaded2 === false ?  <Loader /> : (
                  <>
                  { Array.isArray(rangeArr) ? (
                     <ResponsiveContainer width={500} height={300}><LineChart  data={rangeArr} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                       <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} dataKey="day" padding={{ left: 20, right: 20 }} tickCount='7' minTickGap={10} ticks={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']} domain={['Mon', 'Sun']} />
                       <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[40, 100]} minTickGap={12} tickCount='6' ticks={['40', '52', '64', '76', '88', '100']}  />
                       <Tooltip  />
                       <Line connectNulls  allowDecimals={false} dot={true} isAnimationActive={false} activeDot={true} type="monotone" dataKey="range_score" stroke="#0051A6"  name='Range' />
                     </LineChart></ResponsiveContainer>
                  ):''} </> )}
                  <div className='chartlabel'>Week</div>
                </div>


                <div className='col-wrapper exercise' id='gcs2'>


                  <div className='total-cal dark-text'>Range Score</div>   
                  
                  { loaded7 === false ?  <Loader /> : (
                  <>
                  <div className='text'>&#60; 4</div>
                  { Array.isArray(extrarangeF) ? (
                     <ResponsiveContainer width={500} height={150}><LineChart data={extrarangeF} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                       <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} dataKey="day"  padding={{ left: 20, right: 20 }} style={{ fontSize: '0.85rem' }}  tickCount='7' minTickGap={10} ticks={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']} domain={['Mon', 'Sun']} />
                       <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[0, 20]} minTickGap={12} tickCount='6' />
                       <Tooltip  />
                       <Line connectNulls  allowDecimals={false} dot={true} isAnimationActive={false} activeDot={true} type="monotone" dataKey="cat_70" stroke="#0051A6"  name='Range' />
                     </LineChart></ResponsiveContainer>
                  ):''}
                  <div className='text'>&#62; 10</div>
                    { Array.isArray(extrarangeF) ? (
                     <ResponsiveContainer width={500} height={150}><LineChart  data={extrarangeF} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                       <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} dataKey="day" padding={{ left: 20, right: 20 }} style={{ fontSize: '0.85rem' }} tickCount='7' minTickGap={10} ticks={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']} domain={['Mon', 'Sun']} />
                       <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} minTickGap={12} tickCount='6'  domain={[0, 20]} />
                       <Tooltip  />
                       <Line connectNulls  allowDecimals={false} dot={true} isAnimationActive={false} activeDot={true} type="monotone" dataKey="cat_180" stroke="#0051A6"  name='Range' />
                     </LineChart></ResponsiveContainer>
                  ):''}
                   </> )}
                  <div className='chartlabel'>Week</div>
                </div>


                

                <div className='col-wrapper exercise' id='gcs3'>
                  

                  <div className='filter-wrapper'>
     
                    <div className='left-div'><div className='total-cal dark-text'>Fasting Glucose
                  <span>mmol/l</span>
                  </div>
                      
                    </div>
                    <div className='right-div'>
                      <Select readOnly dropdownPosition="bottom" placeholder='6 am daily' defaultValue={'6 am daily'} data={[{value:'6am', label:"6 am daily"}]}   ></Select>
                    </div>
                  </div>

                  { loaded === false ?  <Loader /> : (
                  <>
                  { Array.isArray(fastArr) ? (
                     <ResponsiveContainer width={'100%'} height={300}><LineChart data={fastArr} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                       <CartesianGrid vertical={false} strokeDasharray="10 10" />
                       <XAxis axisLine={false} tick={{ fill: '#111' }} tickLine={false} dataKey="day" padding={{ left: 20, right: 20 }} tickCount='7' minTickGap={10} ticks={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ]} domain={['Mon', 'Sun']} />
                       <YAxis axisLine={false} tick={{ fill: '#111' }} tickLine={false} domain={[3, 9]} minTickGap={30} tickCount='7' ticks={['3', '4', '5', '6', '7','8', '9']} />
                       <Tooltip />
                       <Line connectNulls  allowDecimals={false} dot={true} isAnimationActive={false} activeDot={true} type="monotone" dataKey="blood_glucose_mg_per_dL" stroke="#0051A6"  name='Glucose' />
                     </LineChart></ResponsiveContainer>
                  ):''}</> )}
                  <div className='chartlabel'>Week</div>
                </div>


                <div className='col-wrapper exercise' id='gcs4'>
                <div className='filter-wrapper'>

                <div className='left-div'><div className='total-cal dark-text'>Average Glucose - Daily Average</div>  </div>
                <div className='right-div'>
                      <Select dropdownPosition="bottom" placeholder='7 days' defaultValue={'no'}  data={[{value: 'no', label: '7days'}, {value: 'yes', label: '14 days'}]} onChange={(val) => dayscroll(val) } ></Select>
                    </div>
                </div>
                  
                  { loaded5 === false ?  <Loader /> : (
                  <>
                  { Array.isArray(fortyday) ? (
                     <ResponsiveContainer width={'100%'} height={300}><BarChart  data={fortyday} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                       <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} dataKey="date"  tickFormatter={(date) => moment(date).format("ddd")}  />
                       <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[3, 15]} tickCount='5' ticks={['3', '6', '9', '12', '15']}/>
                       <Tooltip   cursor={{fill: 'transparent'}} />
                       <Bar type="monotone" dataKey="glucose_avg" stroke="#18ACBC" barSize={15}  name='Glucose' />
                     </BarChart></ResponsiveContainer>
                     
                  ):''} {daysNav === '1' ? (<div className='dayscroller'><span className={selectedItem==='first' ? 'active' :''}><IconChevronLeft className='active' onClick={()=> (nextPrev('last'), setSelected('last'))} /></span><span>Week</span><span className={selectedItem ==='last' ? 'active' :''}><IconChevronRight className='active' onClick={()=> (nextPrev('first'), setSelected('first'))} /></span></div>) : ( <div className='dayscroller'>Week</div>) }</> )}
                 
                </div>


                


                <div className='col-wrapper exercise' id='gcs5'>
                  
                <div className='filter-wrapper'>
                    <div className='left-div'><div className='total-cal dark-text'>Glucose Management Index (GMI)  <span>%</span></div>
                      
                    </div>
                    <div className='right-div'>
                    <Select dropdownPosition="bottom" placeholder='7 days' defaultValue={'no'}  data={[{value: 'no', label: '7days'}, {value: 'yes', label: '14 days'}]} onChange={(val) => dayscroll1(val) } ></Select>
                    </div>
                  </div>

                
                  { loaded1 === false ?  <Loader /> : (
            <>
                  { Array.isArray(fortyday1) ? (
                     <ResponsiveContainer width={'100%'} height={300}><BarChart  data={fortyday1} margin={{ top: 5, right: 0, left:0, bottom: 5 }}>
                    <CartesianGrid vertical={false} strokeDasharray="10 10" />
                     <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} dataKey="date" tickFormatter={(date) =>  moment(date).format("ddd")}  /* tickCount='7' minTickGap={50}ticks={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']} domain={['Mon', 'Sun']}*/  />
                     <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[0, 12]} tickCount='7' minTickGap={2} ticks={['0','2','4', '6', '8', '10', '12']} />
                     <Tooltip cursor={{fill: 'transparent'}}  />
                     <Bar barSize={15} dataKey="gmi"  fill="#18ACBC"   name='Glucose' />
                   </BarChart></ResponsiveContainer>
                  ):''} {daysNav1 === '1' ? (<div className='dayscroller'><span className={selectedItem1==='first' ? 'active' :''}><IconChevronLeft className='active' onClick={()=> (nextPrevGMI('last'), setSelected1('last'))} /></span><span>Week</span><span className={selectedItem1==='last' ? 'active' :''}><IconChevronRight className='active' onClick={()=> (nextPrevGMI('first'), setSelected1('first'))} /></span></div>) : ( <div className='dayscroller'>Week</div>) }</> )}
                  
                </div>

                  

                <div className='col-wrapper exercise' id='gcs6'>
                  <div className='filter-wrapper'>

                    <div className='left-div'><div className='total-cal dark-text'>Average Glucose - Day Details</div>
                      
                    </div>
                    <div className='right-div'>
                 
                    <Select data={data} defaultValue={defaultDate.toString()} onChange={(val)=> {setDay(val) }} />
                    </div>
                  </div>

                  
                  { loaded6 === false ?  <Loader /> : (
                  <>
                  { Array.isArray(rangeAveDay) && rangeAveDay?.[0]?.[day-1]?.full_day_data ? (
                     <ResponsiveContainer width={'100%'} height={300}><BarChart  data={ rangeAveDay[0][day-1].full_day_data} margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                       <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} dataKey="time" tickFormatter={(time) => {var a = (time.replace(/[to]/g, '')).replace('__', '-'), b=moment(a.split('-')[1], 'Hh').format('h'), c =moment(a.split('-')[0], 'Hh').format('h'); return c+'-'+b}}  />
                       <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[3, 15]} tickCount='5' ticks={['3', '6', '9', '12', '15']}/>
                       <Tooltip cursor={{fill: 'transparent'}}  />
                       <Bar type="monotone" dataKey="glucose_avg" stroke="#18ACBC" barSize={15}  name='Glucose' />
                     </BarChart></ResponsiveContainer>
                  ):(<>
                     <ResponsiveContainer width={'100%'} height={300}><BarChart   margin={{ top: 5, right: 30,  left: 20, bottom: 5, }} >
                        <CartesianGrid vertical={false} strokeDasharray="10 10" />
                       <XAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} data={['12-3', '3-6', '6-9', '9-12', '12-3', '3-6', '6-9', '9-12']} domain={['12-3', '3-6', '6-9', '9-12', '12-3', '3-6', '6-9', '9-12']} tickCount='8' ticks={['12-3', '3-6', '6-9', '9-12', '12-3', '3-6', '6-9', '9-12']}  />
                       <YAxis axisLine={false} tickLine={false} tick={{ fill: '#111' }} domain={[3, 15]} tickCount='5' ticks={['3', '6', '9', '12', '15']}/>
                       <Tooltip cursor={{fill: 'transparent'}}  />
                       <Bar type="monotone" dataKey="glucose_avg" stroke="#18ACBC" barSize={15}  name='Glucose' />
                     </BarChart></ResponsiveContainer>
                  </>)} </> )}
                 
                </div>

                  
                  </div>
                </div>

    
  );
}

export default Userglucose;
