import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Suspense, lazy } from 'react';
import Header from '../../header';
import './User.css';
import {IconArrowLeft, IconArrowRight, IconCalendar,  IconChevronLeft, IconChevronRight, IconCircleArrowUp } from "@tabler/icons-react";
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link, Navigate, useNavigate  } from 'react-router-dom';
//import bodyIco from '../../images/body.svg';
import medicinIco from '../../images/medicines.svg';
import bpIco from '../../images/blood-pressure.svg';
import fgIco from '../../images/fg.svg';
import pulsIco from '../../images/puls.svg';
import scIco from '../../images/sc.svg';
import stressIco from '../../images/stress.svg';
import wtIco from '../../images/weight.svg';
import htIco from '../../images/height.svg';
import chatIco from '../../images/chat.svg';
import { PDFDownloadLink, Document, Page, View, Image,Text } from '@react-pdf/renderer';
import userImage from '../../images/user.svg';

/*import Usermeals from '../Usermeals/Usermeals';
import Userappointment from '../Userappointment/Userappointment'
import Userexercise from '../Userexercise/Userexercise';
import Insights from '../Insights/Insights';
import Userglucose from '../Userglucose/Userglucose';
import Loader from '../Loader/Loader';
import Userwellnessprofile from '../Userwellnessprofile/Userwellnessprofile';
import Userweightheight from '../Userweightheight/Userweightheight';*/

const Loader = React.lazy(() => import("../Loader/Loader.js"));
//const Chatappbox = React.lazy(() => import("../Chatsendbird/Chatapp.js")) 
//const Userappointment = React.lazy(() => import("../Userappointment/Userappointment.js"));
const Userexercise = React.lazy(() => import("../Userexercise/Userexercise.js"));
const Insights = React.lazy(() => import("../Insights/Insights.js"));
const Userwellnessprofile = React.lazy(() => import("../Userwellnessprofile/Userwellnessprofile.js"));
const Userweightheight = React.lazy(() => import("../Userweightheight/Userweightheight.js"));
//const UserChat = React.lazy(() => import("../Chat/Chat.js"));
const Usermeals = React.lazy(() => import("../Usermeals/Usermeals.js"));
const Userglucose = React.lazy(() => import("../Userglucose/Userglucose.js")); 

const base_url = process.env.REACT_APP_BASE_URL;

function User() {

  const [startDate, setStartDate] = useState([{}]);
  const DATE = localStorage.getItem("DATE");

  if(DATE==='' || DATE===null){
    localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));
  }

  const setDate = (date)=> {
    localStorage.setItem("DATE", moment(date, 'YYYY-MM-DD').format("YYYY-MM-DD"));
  }
  
  const startDay = moment(DATE).startOf('isoWeek').format('MMM DD');
  const endDay = moment(DATE).endOf('isoWeek').format('MMM DD');
 
  const changeWeek = ()=>{
    var a = moment(DATE).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
    localStorage.setItem("DATE", moment(a, 'YYYY-MM-DD').format("YYYY-MM-DD"));
    refresh()
  }
  const changeWeekNxt = ()=>{
    var a = moment(DATE).add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
    localStorage.setItem("DATE", moment(a, 'YYYY-MM-DD').format("YYYY-MM-DD"));
    refresh()
  }

const [profileData, setProfileData] = useState([]);

const queryParameters = new URLSearchParams(window.location.search);
const UID = queryParameters.get("user");
const UDATE = DATE;
const user = localStorage.getItem("user").replace(/['"]+/g, '');
const  [nxtUser, setnxt] = useState([{}]);
const  [prvUser, setprv] = useState([{}]);
const role =localStorage.getItem("userrole");
const userName = localStorage.getItem("userName");
const gname = localStorage.getItem("userGroup");


const userload = {
    "uuid": UID,
    "date": UDATE
}

const [loadingg , setloading] = useState(true);
const [loading1 , setloading1] = useState(true);


async function userList(){
  try{
    const response = await axios({ method: "get", url:base_url+"/admin/user/get-all-uuids", 'Content-Type': 'application/json', headers: {"Authorization" : `Bearer ${user}`} });
    let lLen = (response.data.response).length;
    let i = (response.data.response).findIndex((obj)=> obj === UID );
      if(i<1){
        setnxt('undefined');
       setnxt((response.data.response)[i+1]);
      }else if((i+1)===lLen){
        setnxt('undefined');
        setprv((response.data.response)[i-1]);
      }else{
        setnxt((response.data.response)[i+1]);
        setprv((response.data.response)[i-1]);
      } 
  } finally{
    setloading(false);
  }
}


async function getData(){
      // user Basic info
   try{  
   const response = await axios({ method: "post", url:base_url+"/admin/user/get-user", data: userload, 'Content-Type': 'application/json', headers: {"Authorization" : `Bearer ${user}`} })
   localStorage.removeItem('channel');
   localStorage.removeItem('group');
   //console.log(response);
   setProfileData(response.data.response);
    if(response.data?.channel_name?.[0]?.channel_name){
      //console.log(response.data?.channel_name?.[0]?.channel_name)
      localStorage.setItem('channel', response.data?.channel_name?.[0]?.channel_name)
      localStorage.setItem('group', response.data?.group)
    } 
   
   
   } finally{
    if(role ==='coach' && (gname===null || gname==='') ){ navigate('/Mygroups'); 
    
  }
    setloading1(false);
   }


} 

  const [today, setToday] = useState('');
  const [ready, setReady] = useState(false);



  
  useEffect(() => {
    if(loadingg===true){userList();}
    if(loading1===true){getData();}
      
    
     if(UID==="" || UID===null){
        <Navigate to="/Dashboard" ></Navigate>
     }
    localStorage.setItem('UID', JSON.stringify(UID));
    localStorage.setItem('UDATE', JSON.stringify(UDATE));
    if(profileData.length > 0){
      localStorage.setItem('email', profileData[0].email);
    }


    if(document.getElementById('header1')!== null  && document.getElementById('screen6') !== null ){
      //setReady(true)
      // DataLoad();  
       setTimeout(()=>{ DataLoad();  setReady(true) },8000)

    }


  }, [today, startDate, profileData, Loader, PDFDownloadLink ]);

//console.log(profileData)

  const navigate = useNavigate();
  const logout = (e) => {
    /* let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k)) */
      localStorage.clear();
    navigate('/Login');
  }

/*   const logoutforadmin = (e) => {
    let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k))
    navigate('/Adminlogin');
  } */



  const refresh = () =>  ( setTimeout(function(){window.location.reload(true)}, 1000));
const resetTime = () => (localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD')));


const [toggle, setToggle] = useState(true);
function toggleActive(){if(toggle===true){setToggle(false)}else{setToggle(true)}}


const [load, setdataImg] = useState('');
const [load1, setdataImg1] = useState('');
const [load2, setdataImg2] = useState('');
const [load3, setdataImg3] = useState('');
const [load4, setdataImg4] = useState('');
const [load5, setdataImg5] = useState('');
const [load6, setdataImg6] = useState('');
const [load7, setdataImg7] = useState('');
const [load8, setdataImg8] = useState('');
const [load9, setdataImg9] = useState('');

var htmlToImage = require("html-to-image");

const DataLoad = async()=>{
 
 setdataImg(await htmlToImage.toPng(document.getElementById('header1')))
 setdataImg1(await htmlToImage.toPng(document.getElementById('screen1')))
 setdataImg2(await htmlToImage.toPng(document.getElementById('screen2')))
 setdataImg3(await htmlToImage.toPng(document.getElementById('screen3')))
 setdataImg4(await htmlToImage.toPng(document.getElementById('screen4')))
 setdataImg5(await htmlToImage.toPng(document.getElementById('screen5')))
 setdataImg6(await htmlToImage.toPng(document.getElementById('screen6')))
 setdataImg7(await htmlToImage.toPng(document.getElementById('screen7')))
 //setdataImg8(await htmlToImage.toPng(document.getElementById('screen8')))
 //setdataImg9(await htmlToImage.toPng(document.getElementById('screen9')))
}


const styles ={
  page: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    padding: '40px'
  },
}

const MyDoc = () => (<Document style={{  width: "100%"}}>
<Page size="A2" style={styles.page}>
  <Text style={{ fontSize: '24px', fontWeight: '900', marginBottom: "10px"}}>Wellness & CGM Monitoring Report</Text>
<View style={{  flex: 1, flexGrow: 10, flex: '0 0 100%',  paddingBottom: '10px' }}>
  <Image src={load} ></Image>
</View>
  <View style={{ flex: 1, flexGrow: 7, paddingRight: '20px' }}>
  <Image src={load1} ></Image>
  <Image src={load2} ></Image>
  <Image src={load3} ></Image>
  <Image src={load4} ></Image>
  <Image src={load5} ></Image>
  <Image src={load6} ></Image>
  </View>
  <View style={(styles.section, { flex: 1, flexGrow: 3})}>
  <Image src={load7} ></Image>
 {/*  <Image src={load8} ></Image> */}
  {/* <Image src={load9} ></Image> */}
  </View>
</Page>
  </Document>);
//const MyDoc1 = () => ( <Document><Page style={{ height: "50vh", width: "100%"}} ><View ><Image src={load1} /></View></Page></Document>);


//console.log(prvUser+'---'+nxtUser);
  return (
    
    <div className="App">
        

      <div className="page-wrpper profilepage" > 
      <Header />
    <div className='content-wrapper'>
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
              <div className='wrapper' status={today ? "active" : null}>
            </div>
            </div>
            <div className='right-content'>
            <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link>
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ role }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
            </div>
          </div>

          <div className='header-left'>
          
        </div>

      
        <div className='header-right'>
      { load.length && load1.length &&  load2.length &&  load3.length && load4.length && load5.length && load6.length && load7.length && /* load8.length && */ /* load9.length && */ ready===true ? <>
              <PDFDownloadLink document={<MyDoc />} id="pdflink" fileName={'Deatils-'+UID+'-'+DATE+'.pdf'}>
      {({ blob, url, loading, error }) => 
        loading ? 'PDF' : 'PDF'
      }
   
    </PDFDownloadLink></> : ''}

    
      </div>

      </div>

      <div className='main-content' >

     {/*  {loading===true ? <Loader />: ''} */}
   
        {profileData.map(
        (
          { 
          created_at,gender,dob,firstname,email,
          goals:{body_weight_goal, meal_goal},objective,glucose_level,
          height:{ht_date, height, height_unit},
          blood_pressure:{bp_date, high_blood_pressure, low_blood_pressure},
          is_registered,lastname,mobile_number, 
          pulse:{pl_date, pulse_rate},sleep_duration,sleep_quality,stress_level,updated_at,uuid,
          weight: {wt_date, weight, weight_unit}, medications, group_name, device, device_name, last_active

             },
          index
        ) => (
          
            <div  key={index} >
              { localStorage.setItem("nickname", firstname+' '+lastname) }
          <div className='user-title-bar' id='header1'>
            <div> 
              {prvUser==='undefined' || role !=='admin' ? '' : (<Link className='prev-arrow' to={'/user?user='+prvUser} onClick={()=> {refresh(); resetTime() }}><IconChevronLeft /></Link>)} 
              {firstname} {lastname} 
              {nxtUser==='undefined' || role !=='admin' ? '' : (<Link className='next-arrow' to={'/user?user='+nxtUser} onClick={()=> {refresh(); resetTime() }}><IconChevronRight /></Link>)}</div> 
          <div className='filter item datefilter'>
            <div className='weekset'>
              <span className='week previos' onClick={()=>changeWeek()}><IconArrowLeft></IconArrowLeft></span>
              <span className='week start'>{startDay} -</span>
              <span className='week start'>{endDay}</span>
              {moment(DATE).isBefore(moment(), "day") ? <>
              <span className='week previos next' onClick={()=>changeWeekNxt()}><IconArrowRight /></span>
              </> : ''}
              </div>
          <div className="filter-title" ><DatePicker isClearable={false}  dateFormat="MMM dd, yyyy" selected={moment(DATE).toDate()} placeholderText={moment(new Date()).format("MMM DD, YYYY")}  onChange={(date) => { setDate(date); setStartDate(date);setToday(''); setloading(true); refresh() }}  /> <IconCalendar size={20} /></div>
          </div>
          </div>
          
            <div className='user-data-content'>
              <div className='left-content'>
                <div id="screen1">
                <div className='col col-45'>
                  <div className='col-wrapper two-col'>
                    <div className='user-header'>
                      <div className='user-pic'></div>
                      <div className='user-info'>
                          <span className='usernamr'> {firstname} {lastname} </span>
                          <span className='gender-age'>{gender} / { -(moment(dob).diff(moment(), 'years'))}</span>
                      </div>
                    </div>
                    <div className='item'>
                        <span className='label'>Phone</span>
                        <span className='text'> { mobile_number }</span>
                    </div>
                    <div className='item'>
                        <span className='label'>Email</span>
                        <span className='text'> { email }</span>
                    </div>
                    <div className='item'>
                        <span className='label'>Membership</span>
                        <span className='text'> Basic</span>
                    </div>
                    <div className='item'>
                        <span className='label'>Objective</span>
                        <span className='text'> {objective === 'g'? 'Glucose Control' :''} {objective === 'w'? 'Weight Loss' :''} {objective === 'gw'? 'Glucose Control | Weight Loss' :''}</span>
                    </div>
                    <div className='item'>
                        <span className='label'>Goal</span>
                        <span className='text'><ul>{body_weight_goal===null ? '' :<li>Body Weight - {body_weight_goal} kg</li>} {meal_goal === 'null'? '' :<li>Daily calories intake - {meal_goal} cal</li>} </ul></span>
                    </div>
                    <div className='item'>
                        <span className='label'>Member since</span>
                        <span className='text'>{dayjs.unix(created_at).format("DD MMM YYYY") }</span>
                    </div>
                    <div className='item'>
                        <span className='label'>Group</span>
                        <span className='text'>{group_name}</span>
                    </div>
                    <div className='item inline-items'>
                        <div>
                          <span className='label'>Device</span>
                          <span className='text'> {device_name}, {device}</span>
                        </div>
                        <div>
                          <span className='label'>Last active</span>
                          <span className='text'>{ moment(last_active).format("DD MMM YYYY")}</span>
                        </div>
                    </div>

                  </div>  
                </div>  
                <div className='col col-55'>
                <h2>Meals & Health</h2>
                <div>
               
                
                  {Array.isArray(medications) ? (
                    <>
                    <div className='col-group'>
                  <div className='col-wrapper'>
                    <div className='item'>
                      <span className='icon'><img src={medicinIco} /></span>
                      <div className='data'>
                        <span className='label'>Medications</span>
                       
                        <div>

                        {medications.map(({medication_name,value,time, medication_type },i)=>(
                            <div  key={i} > 
                            <div><span className='text'></span>
                        <span className='values'>{medication_name}</span><span className='val-text'>{value} ({ medication_type })</span> 
                          </div>
                             </div> 
                          
                         ))}
                         
                        




                          </div>
                            
                        
                      </div>
                    </div>
                  </div>  
                  </div>
                  </>) : 
                  <>
                  <div className='col-group percent-height'>
                   <div className='col-wrapper'>
                    <div className='item'>
                      <span className='icon'><img src={medicinIco} /></span>
                      <div className='data'>
                        <span className='label'>Medications</span>
                       
                        <div></div>
                        </div>
                        </div>
                        </div>

                        <div className='col-wrapper'>
                          <div className='item'>
                            <span className='icon'><img src={fgIco} /></span>
                            <div className='data'>
                              <span className='label'>Fasting Glucose</span>
                              <span className='text'><strong>{ glucose_level }</strong> {glucose_level === 'No data' ? '' : 'mmol/l'}</span>
                                {/* {glucose_level === 'No data' ? '' : <><span className='text date'>Last updated On</span>
                                  <span className='text'> { moment(date2).format('DD MMM YYYY') }</span>
                                </>} */}
                      </div>
                    </div>
                          </div>
                          </div>
                  </>
                          
                  } 
                
                <div className='col-group'>
                    <div className='col-wrapper'>
                      <div className='item'>
                        <span className='icon'><img src={bpIco} /></span>
                        <div className='data'>
                          <span className='label'>Blood Pressure</span>
                          <div className='two-col'>
                            <div className='item'>
                                <span className='text'>High Range</span>
                                <span className='text'><strong>{ high_blood_pressure }</strong>{high_blood_pressure === 'No data' ? '' : 'mmHg'}</span>
                                
                            </div>
                            <div className='item'>
                              <span className='text'>Low Range</span>
                              <span className='text'><strong>{ low_blood_pressure }</strong>{low_blood_pressure === 'No data' ? '' : 'mmHg'}</span>
                            </div>
                          </div>
                          {bp_date === 'No data' ? '' : <>
                              <span className='text date'>Last updated On</span>
                        <span className='text'> {bp_date}</span>
                          </>}
                          
                        </div>
                      </div>
                    </div>
                    


                    <div className='col-wrapper'>
                      <div className='item'>
                        <span className='icon'><img src={scIco} /></span>
                        <div className='data'>
                          <span className='label'>Sleep Condition</span>
                          <span className='text'>{ sleep_quality }</span>
                          <span className='text'><strong>{ sleep_duration }</strong> {sleep_duration === 'No data' ? '' : 'Hrs'}</span>
                        </div>
                      </div>
                    </div>

                          </div>
                <div className='col-group'>
                <div className='col-wrapper'>
                      <div className='item'>
                        <span className='icon'><img src={pulsIco} /></span>
                        <div className='data'>
                          <span className='label'>Pulse</span>
                          <span className='text'>BPM</span>
                          <span className='text'><strong>{ pulse_rate }</strong></span>
                        </div>
                        {pl_date === 'No data' ? '' : <>
                        <span className='text date'>Last updated On</span>
                        <span className='text'> { moment(pl_date, 'YYYY-MM-DD').format('DD MMM YYYY') }</span>
                        </>}
                      </div>
                    </div>
                    <div className='col-wrapper'>
                      <div className='item'>
                        <span className='icon'><img src={stressIco} /></span>
                        <div className='data'>
                          <span className='label'>Stress</span>
                          <span className='text'><strong>{ stress_level }</strong></span>
                        </div>
                      </div>
                    </div>          
                            


                <div className='col-wrapper'>
                      <div className='item'>
                        
                        <div className='data weight'>
                          <span className='label'></span>
                            <div className='item'>
                            <span className='icon'><img src={wtIco} /></span>
                                <span className='text'><strong>Weight</strong> </span>
                                <span className='text'><strong>{weight ? weight.toFixed(2) :'' }</strong> kg</span>
                                {wt_date === 'No data' ? '' : <><span className='text date'>Last updated On</span>
                                <span className='text'> { moment(wt_date, 'YYYY-MM-DD').format('DD MMM YYYY') }</span>
                                
                                </>}
                            </div>
                        </div>
                      </div>
                    </div>




                    <div className='col-wrapper'>
                      <div className='item'>
                        
                        <div className='data weight'>
                          <span className='label'></span>
                            <div className='item'>
                            <span className='icon'><img src={htIco} /></span>
                              <span className='text'><strong>Height</strong></span>
                              <span className='text'><strong>{height ? height.toFixed(2) : '' }</strong> {height_unit}</span>
                              {ht_date === 'No data' ? '' : <>
                              <span className='text date'>Last updated On</span>
                              <span className='text'> { moment(ht_date, 'YYYY-MM-DD').format('DD MMM YYYY') }</span></>}
                            </div>
                          </div>
                      </div>
                    </div>
                    
                    </div>
                    {Array.isArray(medications) ? 
                      <div className='row'>
                        <div className='col-group'>
                          <div className='col-wrapper'>
                          <div className='item'>
                            <span className='icon'><img src={fgIco} /></span>
                            <div className='data'>
                              <span className='label'>Fasting Glucose</span>
                              <span className='text'><strong>{ glucose_level }</strong> {glucose_level === 'No data' ? '' : 'mmol/l'}</span>
                                {/* {glucose_level === 'No data' ? '' : <><span className='text date'>Last updated On</span>
                                  <span className='text'> { moment(date2).format('DD MMM YYYY') }</span>
                                </>} */}
                      </div>
                    </div>
                          </div>
                        </div>
                      </div>
                    :''}

                </div>
                                </div>
                </div>

                <div id="screen2">    
                    <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>}><Userglucose /></Suspense>
                  </div>
                <div id="screen3">
                    <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>}><Usermeals /></Suspense>
                </div>

                <div id="screen4">    
                    <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>}><Userexercise /></Suspense> 
                  </div>
               
                  <div id="screen5">
                    <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>}><Userweightheight /></Suspense>  
                  </div>
                  
                  <div id="screen6">      
                    <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>}><Userwellnessprofile /></Suspense>
                  </div>
              </div> 
              <div className='right-content'>
              <div id="screen7">
              <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>}><Insights /></Suspense>
               </div>

             {/*  < div id="screen8">          
                          <div className='col col-100'>
                  
                  <div className='col-group'>
                  <div className='col-wrapper appointments chat'>
                  
                      <h2  onClick={()=> toggleActive()}  className= { toggle === true ? 'hide' :  'show'  }  style={{margin:"0"}}><img src={chatIco} /> Chat -  {firstname} {lastname} <span className='collapsible-icon'><IconCircleArrowUp /></span></h2>
                     */} {/*  { toggle === true ? <>  */}
                     {/* <Suspense */} {/* /* fallback={<div className='spinner-wrapper'><Loader/></div>}  /* <Chatappbox></Chatappbox></Suspense> */}
                     {/*  <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>} > <UserChat></UserChat></Suspense> */} 
                      
                      {/*  </> : '' */} 
                 {/*  </div>
                  
                  </div>
                </div>
                </div> */}

{/*                 <div id="screen9">
                <Suspense fallback={<div className='spinner-wrapper'><Loader/></div>}><Userappointment /></Suspense>
                  </div> */}     
                </div> 
            </div>


        </div>
        
          )
        )}
      

     
</div>

    </div>

</div>
           
</div>

    
  );

}

export default User;
