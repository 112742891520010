
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header';
import './Groups.css';
import { Box, Grid, TextInput, Checkbox, Group , Button} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { IconSearch, IconArrowRight, IconPlus, IconPencil, IconTrash } from "@tabler/icons-react";
import moment from 'moment';
import { Link,  useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import dayjs from 'dayjs';
import userImage from '../../images/user.svg';
import { IMaskInput } from 'react-imask';

function Groups() {

const [loaded, dataLoading] = useState(true);
const [error, setError] = useState(false);
const user = localStorage.getItem("user").replace(/['"]+/g, '');
const role = localStorage.getItem("userrole");
const userName = localStorage.getItem("userName");
const memberGp = localStorage.getItem('userGroup');

const[assinngGroup, setAssingGroup] = useState('');
const [gorupsData, setGroupData] = useState([]);
const [memberList, setMemberList] = useState([]);
const [coachesList, setCoachesList] = useState([]);
const [mermberCoachList, setmermberCoach] = useState([]);
const [selGroupCoach, setSelGroupCoach] = useState([]);

const [num1, setNum] = useState();
const base_url = process.env.REACT_APP_BASE_URL;
const [membersAdded, setMemberAdded] = useState([])
const [filterdGroup, setFilteredGroup] = new useState([]);
const [filteredList, setFilteredList] = new useState(memberList);



function getData(){
  if(role !=='admin'){ navigate('/Login'); }
  axios({ method: "POST", url:base_url+"/admin/get-group-lists", crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    dataLoading(true)
   // console.log(response.data.response)
      if(response.data.status===200){ setGroupData(response.data.response); setNum(1); setFilteredGroup(response.data.response) }else{ setGroupData('No data')}
  })
}
function getMembers(){
  axios({ method: "POST", url:base_url+"/admin/add-new-group-list", crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    setMemberList(response.data.response); 
    setFilteredList(response.data.response); 
    console.log(response.data.response);
  })
}
function getCoaches(e, i){
  setMemberAdded('')
  setAssingGroup(e)
  setSelGroupCoach(i)
  axios({ method: "POST", url:base_url+"/admin/get-all-coach-list", crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    //setCoachesList(response.data.response); 
    if(response.data.response !== "No data found"){
     // console.log(response.data.response);
      setCoachesList(response.data.response); 
      
      }
  })
  
}

const formEdit = useForm({
  initialValues: {
    user_group_list: [],
    email_list: [],
    updated_group_name : '',
    updated_group_contact_name:'',
    updated_group_email_address:'',
    updated_group_phone_number:'',
    updated_group_city:'',
    updated_group_state:''
  },
  validate: {
  //  updated_group_phone_number: (value) => (value.length > 0 ?  value.length < 10 ? 'Invalid Contact Number' : null : null),
  //  updated_group_email_address: (value) => (value.length > 0 ?   /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address' : null)    
  }
});

function getEditGroup(e){
  setmermberCoach('');
  setMemberAdded('');
  const a = {"group_name": e}
  setAssingGroup(e)
  axios({ method: "POST", url:base_url+"/admin/edit-show-list", data: a,  crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    setmermberCoach(response.data);
    console.log(response.data.group_data[0])
    formEdit.setValues({updated_group_name: e, 
      updated_group_contact_name: response.data.group_data[0].group_contact_name, 
      updated_group_email_address: response.data.group_data[0].group_email_address,
      updated_group_phone_number: response.data.group_data[0].group_phone_number,
      updated_group_city: response.data.group_data[0].group_city,
      updated_group_state: response.data.group_data[0].group_state
     })
    response.data?.user_list?.map((i)=> setMemberAdded(oldArray => [...oldArray, i.uuid]))
  })
}

const form = useForm({
  initialValues: {
    user_group_list: []
  },
  validate: {
    group_name: (value) => (value.length < 2 ? 'Invalid Group Name' : null),
  //  group_phone_number: (value) => (value.length > 0 ?  value.length < 10 ? 'Invalid Contact Number' : null : null),   
  //  group_email_address: (value) => (value.length > 0 ?   /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address' : null)   
  },
});

const formMembers = useForm({
  initialValues: {
    user_group_list: [],
    group_name: []
  }
});

const formAssign = useForm();

function handleSubmit(e) {
  dataLoading(false)
 // console.log(e)
  axios({ method: "POST", url:base_url+"/admin/add-new-group", data: e, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
   //console.log(response); 
    if(response.data.response==="Added new group successfully"){ dataLoading(true); setActive(false); form.reset()  }else{
      dataLoading(true); setError('Fail to add Group!')
    }
  })
}

function handleDelete(e){
  dataLoading(false)
  axios({ method: "POST", url:base_url+"/admin/delete-group", data: {"group_name": e}, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
  // console.log(response.data.response); 
    if(response.data.response==="Group deleted successfully"){ dataLoading(true); setDelete('')  }else{
      dataLoading(true); setError('Fail to Delete Group!')
    }
  })
}

function handleAssign(e) {
  dataLoading(false)
const obj = []
coachesList.map(function(a) {
  if(e.coach_list){
  let matched = e.coach_list.filter(b => a.email === b);
  if (matched.length) { obj.push({email: a.email, name:a.name}); } 
  }
 })
 const a = {
  "coach_list" :  obj,
  "group_name": assinngGroup.toString()
}
  axios({ method: "POST", url:base_url+"/admin/assign-coach-to-group", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
   // console.log(response.data.response); 
    if(response.data.response==="Assigned the coach to the group successfully"){ dataLoading(true); setCoach(false); formAssign.reset()  }else{
      dataLoading(true); setError('Fail to assign Coach!')
    }
  }) 
}

function handleEdit(e) {
  dataLoading(false)
    const c = (mermberCoachList?.user_list?.length)-(e?.user_group_list?.length);
    const d = assinngGroup===e.updated_group_name ? '' : e.updated_group_name;
   const a = {
    "user_group_list" :  e.user_group_list,
    "member_count" : c,
    "email_list" : e.email_list,
    "group_name": assinngGroup,
    "updated_group_name": d,
    "group_contact_name": e.updated_group_contact_name, 
    "group_email_address": e.updated_group_email_address,
    "group_phone_number": e.updated_group_phone_number,
    "group_city": e.updated_group_city,
    "group_state": e.updated_group_state
  }

    axios({ method: "POST", url:base_url+"/admin/edit-group-list", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
     // console.log(response.data.response); 
      if(response.data.response==="Group updated successfully"){ dataLoading(true); setEditGroup(false); formEdit.reset()  } else{
        dataLoading(true); setError('Your Changes have not been saved!')
      }
    }) 
  }


  function handleMembers(e) {
    dataLoading(false)
      const c = membersAdded?.length + e?.user_group_list?.length;
     const a = {
      "user_group_list" :  e.user_group_list,
      "member_count" : c,
      "group_name": assinngGroup
    }
    //console.log(a)
      axios({ method: "POST", url:base_url+"/admin/add-new-member", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
       // console.log(response.data.response); 
        if(response.data.response==="Added new members"){ dataLoading(true); setAddMembers(false); formMembers.reset(); getEditGroup(assinngGroup)  } else{
          dataLoading(true); setError('Your Changes have not been saved!')
        }
      }) 
    }

const [switchData, setSwithdata] = useState('userlist');
const [selectedGroup, setSelectedGp] = useState([]);
const [gname, setgname] = useState();
function openGoupList(e){
  setgname(e)
  dataLoading(false)
  localStorage.setItem("group", 'grouplist')
  setSelectedGp()
  axios({ method: "POST", url:base_url+"/admin/get-all-member", data:{"group_name": [e]},  crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
   // console.log(response.data.response)
    if(response.data.response !== 'No data found'){setSelectedGp(response.data.response?.sort((a, b) => a.firstname > b.firstname ? 1 : -1,)); setSwithdata('grouplist'); dataLoading(true);
    setTimeout(()=>{localStorage.removeItem('userGroup')}, 500)
  }
  })

}

const navigate = useNavigate();
const logout = (e) => {
  /* let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k)) */
    localStorage.clear();
  navigate('/Login');
}

/* const logoutforadmin = (e) => {
  let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
  navigate('/Adminlogin');
} */

const [pagerVis, setPagerVis] = useState(true);
const [popopActive, setActive] = useState(false)
const [popopDelete, setDelete] = useState('')
const [assignCoach, setCoach] = useState(false)
const [editGroup, setEditGroup] = useState(false)
const [addMembers, setAddMembers] = useState(false)
const [searchActive, setSearchActive] = useState(false);
     useEffect(() => {
      setSwithdata(localStorage.getItem('group'))
      if(searchActive===false){
        getData();
        }
      if(popopActive===false && (popopDelete === null || popopDelete === '') && assignCoach===false && editGroup===false ){
        setError();
      }
      if(memberGp!=='' && memberGp!==null){
        openGoupList(memberGp)
       // console.log(memberGp)
      }else{
        getData()
        setSwithdata('')
        //console.log(memberGp+'++'+switchData+'--'+gorupsData)
      }

  }, [popopDelete, editGroup, assignCoach, popopActive, addMembers]);




  

function filterBySearch (event) {
    var query = event.target.value;
    var updatedList = [...memberList];
    updatedList = memberList.filter((item) => {
      return item?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredList(updatedList);
  };

function filterClear(){
    setFilteredList(memberList);
}


function filterByGroup (event){
  const query = event.target.value;
  var updatedList = [...gorupsData];
  updatedList = gorupsData.filter((item) => {
    return item?.group_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
  setFilteredGroup(updatedList);
};

function blurSerach(e){
  if(e.target.value === '' || e.target.value === null){setSearchActive(false)}
}
function userMemberget(e){
  setSwithdata('grouplist');
  localStorage.setItem('userGroup', e)
}
const EmailMask = /^\S*@?\S*$/;

  return (
    
    <div className="Dashboard" >
      <div className="page-wrpper"> 
      <Header />
      <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>{ gorupsData!== 'No data found' && gorupsData !== 'No data' && (switchData === "grouplist" || (memberGp !== '' && memberGp!==null)) ? <>Member List ({gname})</> : <>Groups List</> } </h1>
            </div>

            
            <div className='right-content'>
            { role ==='admin' ? <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link> : ''}
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ role }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
            </div> 
          </div>
          
 
          
          <div className='header-left'>
          
           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} onFocus={()=> setSearchActive(true)} onBlur={(value)=> blurSerach(value)} onChange={(value)=> filterByGroup(value)} placeholder="Search Group by Name" icon={<IconSearch size={16} />}    />
            </Grid.Col></Grid>
             
        </div> 

        <div className='header-right'>
        <div className='filter item' >
         <div className="filter-title" onClick={()=> (getMembers(), setActive(true), filterClear())}> + Add Group </div> 
        </div> 

      </div>
      
      
      </div>

      <div className='main-content groups-data'>
       
      
      <Box >    
     <DataTable
      noHeader
      records={filterdGroup}
      columns={[
        {cellsClassName: 'group-name', accessor: 'group_name', render: ({group_name, members}) => (
         <>{parseInt(members) > 0 ? <span /* onClick={() => openGoupList(group_name)} */><Link onClick={() => localStorage.setItem('userGroup', group_name)} to={'/Memberlist'}>{group_name}</Link></span> : `${group_name}`}</>
        ), width: 200},
        {accessor: 'group_contact_name', cellsClassName:'gp-address', width: 170},
        {accessor: 'group_phone_number', cellsClassName:'gp-contact', width: 120},
        {cellsClassName: 'members', width: 120, render : ({members}) => `${members}`+' Members', accessor: 'Members'},
        {accessor: 'coaches', cellsClassName:'coaches', width: 250},
        {accessor: 'members', cellsClassName:'ctas', width: 200,  render: ({ group_name, coaches}) => (
            <div className='cta-wrapper'>
              <span onClick={() => ( setCoach(true), getCoaches(group_name, coaches))} className='assign-cta'><IconPlus /> Assign Coach</span>
              <span onClick={() => ( setEditGroup(true), getEditGroup(group_name))} className='edit-cta'><IconPencil /> Edit </span>
              <span onClick={() => setDelete(group_name)} className='delete-cta'><IconTrash /> Delete</span>
            </div> 
            )}
      ]}

    />

{ pagerVis === true  ? ( <>
<div className="mantine-ooem79 pager">
  <div className="mantine-Group-root mantine-kn8vaf">
    {/* <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> { pager(-1) }} data-disbaled={num1===1 ? 'disabled' : 'visible'}  type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M7.219 8l3.3 3.3-.943.943L5.333 8l4.243-4.243.943.943-3.3 3.3z" fill="currentColor"></path></svg></button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(-1)}} data-hidden={num1===1 ? 'disabled' : 'visible'} type="button" >{num1-1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg active" type="button" >{num1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(1)}} data-hidden={num1===a ? 'disabled' : 'visible'}  type="button" >{num1+1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> {pager(1) }} data-disbaled={num1===a ? 'disabled' : 'visible'} type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M8.781 8l-3.3-3.3.943-.943L10.667 8l-4.243 4.243-.943-.943 3.3-3.3z" fill="currentColor"></path></svg></button>
 */}    </div>
    </div>
</>) :''}
</Box>

{loaded === false ? <div className='global-loader'><Loader /></div> : '' }

{ popopActive === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group'>
  <span className='close' onClick={()=> (setActive(false), filterClear())}></span>
  <div className='popup-titlebar'>
      <h2>Add New Group</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={form.onSubmit((values) => ( handleSubmit(values) ))}>
      <div className='two-col-grp'>
        <TextInput className='two-col' withAsterisk label="" required placeholder="Group Name" {...form.getInputProps('group_name')}/>
        <TextInput className='two-col' withAsterisk label=""  placeholder="Contact Name" {...form.getInputProps('group_contact_name')}/>
        <TextInput className='two-col' withAsterisk label="" mask={EmailMask}  placeholder="Email Address" {...form.getInputProps('group_email_address')}/>
        <TextInput className='two-col' component={IMaskInput} mask="0000000000"  placeholder="Contact Number" {...form.getInputProps('group_phone_number')} />
        <TextInput className='two-col' withAsterisk label=""  placeholder="City" {...form.getInputProps('group_city')}/>
        <TextInput className='two-col' withAsterisk label=""  placeholder="State" {...form.getInputProps('group_state')}/>
        </div>
        <div className='search-field-formatting'>
          <TextInput withAsterisk label="" icon={<IconSearch size={16} />}  onChange={(value)=>filterBySearch(value)}  placeholder="Search Member"  />
        </div>

<Checkbox.Group 
      defaultValue={['']}
      label=""
      description=""
      withAsterisk {...form.getInputProps('user_group_list')}
    >
      <Group>
        { filteredList?.map((i, index)=> 
          <Checkbox key={index} value={i.uuid} label={i.name} />
        )}
        
      </Group>
      
    </Checkbox.Group>



        <Group justify="flex-end" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>


</div>
</>
):''}

{ popopDelete !== null && popopDelete !== '' ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group delete-group'>
  <span className='close' onClick={()=> setDelete('')}></span>
  <div className='popup-titlebar'>
      <h2>Delete</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
    <div className='popup-content'>
      <p>Are you sure you want to delete Group { popopDelete }</p>
      <p>Once you delete Group { popopDelete }, all the data will be erased permanently.</p>
    </div>

        <Group justify="flex-end" mt="md">
          <Button className='cancel-cta' onClick={()=> setDelete('')}>Cancel</Button>
          <Button  onClick={()=> handleDelete(popopDelete)}>Yes</Button>
        </Group>
 


</div>
</>
):''}

{ assignCoach === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach'>
  <span className='close' onClick={()=> setCoach(false)}></span>
  <div className='popup-titlebar'>
      <h2>Coaches</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formAssign.onSubmit((values) => ( handleAssign(values) ))}>
        
{/* <h5> Group Name - <strong>{ assinngGroup }</strong></h5> */}

<Checkbox.Group 
      label=""
      description="" 
       {...formAssign.getInputProps('coach_list')}
    >
      <Group>
  
        {coachesList?.map((i, index)=> (
          
           <>{i?.group_name?.includes(assinngGroup) ?'' : <Checkbox key={index} value={i.email} label={i.name} />}</>)
        )}
        
      </Group>
      
    </Checkbox.Group>



    { coachesList?.length < (selGroupCoach?.split("|").filter(n => n.trim() != ''))?.length || coachesList?.length === (selGroupCoach?.split("|").filter(n => n.trim() != ''))?.length  ? <>
    <p style={{ 'textAlign' : 'center' }}> This Group assigned all Coaches or no Coach is available! </p>
        <Group justify="flex-end" mt="md">
          <Button disabled type="submit">Assign Coach</Button>
        </Group>
        </> : <>
        <Group justify="flex-end" mt="md">
          <Button type="submit">Assign Coach</Button>
        </Group>
        </>
        }

      </form>


</div>
</>
):''}

{ editGroup === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach edit'>
  <span className='close' onClick={()=> setEditGroup(false)}></span>
  <div className='popup-titlebar'>
      <h2>Edit</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formEdit.onSubmit((values) => (handleEdit(values) ))}>     

<div className='two-col-grp'>
  <TextInput className='group-nameedit two-col'  label="" required placeholder="Group Name" {...formEdit.getInputProps('updated_group_name')}/>
  <TextInput className='two-col' withAsterisk label=""  placeholder="Contact Name" {...formEdit.getInputProps('updated_group_contact_name')}/>
        <TextInput className='two-col' withAsterisk label=""  placeholder="Email Address" {...formEdit.getInputProps('updated_group_email_address')}/>
        <TextInput className='two-col' component={IMaskInput} mask="0000000000"  placeholder="Contact Number" {...formEdit.getInputProps('updated_group_phone_number')} />
        <TextInput className='two-col' withAsterisk label=""  placeholder="City" {...formEdit.getInputProps('updated_group_city')}/>
        <TextInput className='two-col' withAsterisk label=""  placeholder="State" {...formEdit.getInputProps('updated_group_state')}/>
</div>

<Checkbox.Group className='coach-list'
      label="Coach"
      description="" 
       {...formEdit.getInputProps('email_list')} >
      <Group>
        { mermberCoachList?.coaches_list?.map((i, index)=> <Checkbox key={index} value={i.email} label={i.name} />
        )}
      </Group>
    </Checkbox.Group>


<div className='add-members' onClick={()=>( getMembers(), setAddMembers(true), setEditGroup(false) )}>Add Members</div> 

<Checkbox.Group className='members-list'
      label="Members"
      description="" 
       {...formEdit.getInputProps('user_group_list')} >
      <Group>
        { mermberCoachList?.user_list?.map((i, index)=> 
           <Checkbox key={index} value={i.uuid} label={i.name} />
        )}
      </Group>
    </Checkbox.Group>
        <Group justify="flex-end" mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>


</div>
</>
):''}

{ addMembers === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach memrbes-add'>
  <span className='close' onClick={()=> setAddMembers(false)}></span>
  <div className='popup-titlebar'>
      <h2>Add Members</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formMembers.onSubmit((values) => (handleMembers(values) ))}>     

  <div className='search-field-formatting'>
  <TextInput withAsterisk label="" icon={<IconSearch size={16} />}  onChange={(value)=>filterBySearch(value)}  placeholder="Search Member"  />
        </div>

<Checkbox.Group className='members-list'
      label=""
      description="" 
       {...formMembers.getInputProps('user_group_list')} >
      <Group>
      { filteredList?.map((i, index)=> 
      
          <> {membersAdded?.includes(i?.uuid) ?'' : <Checkbox key={index} value={i.uuid} label={i.name} /> }</>
        )}
      </Group>
    </Checkbox.Group>


        <Group justify="flex-end" mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>


</div>
</>
):''}



</div>

    </div>

</div>


</div>

    
  );

}





export default Groups;
